export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOG_OUT = "LOG_OUT";
export const LOAD_PROFILE = "LOAD_PROFILE";

export const HOME_VIDEO_SUCCESS = "HOME_VIDEO_SUCCESS";
export const HOME_VIDEO_FAIL = "HOME_VIDEO_FAIL";
export const HOME_VIDEO_REQUEST = "HOME_VIDEO_REQUEST";

export const SELECTED_VIDEO_REQUEST = "SELECTED_VIDEO_REQUEST";
export const SELECTED_VIDEO_SUCCESS = "SELECTED_VIDEO_SUCCESS";
export const SELECTED_VIDEO_FAIL = "SELECTED_VIDEO_FAIL";

export const CHANNEL_DETAILS_REQUEST = "CHANNEL_DETAILS_REQUEST";
export const CHANNEL_DETAILS_SUCCESS = "CHANNEL_DETAILS_SUCCESS";
export const CHANNEL_DETAILS_FAIL = "CHANNEL_DETAILS_FAIL";

export const COMMENT_LIST_REQUEST = "COMMENT_LIST_REQUEST";
export const COMMENT_LIST_SUCCESS = "COMMENT_LIST_SUCCESS";
export const COMMENT_LIST_FAIL = "COMMENT_LIST_FAIL";

export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS";

export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAIL = "CREATE_COMMENT_FAIL";

export const RELATED_VIDEO_REQUEST = "RELATED_VIDEO_REQUEST";
export const RELATED_VIDEO_SUCCESS = "RELATED_VIDEO_SUCCESS";
export const RELATED_VIDEO_FAIL = "RELATED_VIDEO_FAIL";

export const SEARCHED_VIDEO_REQUEST = "SEARCHED_VIDEO_REQUEST";
export const SEARCHED_VIDEO_SUCCESS = "SEARCHED_VIDEO_SUCCESS";
export const SEARCHED_VIDEO_FAIL = "SEARCHED_VIDEO_FAIL";

export const SUBSCRIPTIONS_CHANNEL_REQUEST = "SUBSCRIPTIONS_CHANNEL_REQUEST";
export const SUBSCRIPTIONS_CHANNEL_SUCCESS = "SUBSCRIPTIONS_CHANNEL_SUCCESS";
export const SUBSCRIPTIONS_CHANNEL_FAIL = "SUBSCRIPTIONS_CHANNEL_FAIL";

export const CHANNEL_VIDEOS_REQUEST = "CHANNEL_VIDEOS_REQUEST";
export const CHANNEL_VIDEOS_SUCCESS = "CHANNEL_VIDEOS_SUCCESS";
export const CHANNEL_VIDEOS_FAIL = "CHANNEL_VIDEOS_FAIL";
